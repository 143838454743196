























import MiningWagers from './MiningWagers.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { MiningState } from '@/store/modules/mining/MiningState';
import { startMining, stopMining } from '@/api/Mining';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'MiningView',
  components: {
    MiningWagers
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Mining', ['enableMining', 'loadMiningStatus']),
    ...mapState('Mining', {
      wagers: (state: MiningState) => state.wagers.data
    }),
    disableMiningSwitch(): boolean {
      return (
        this.loadMiningStatus ||
        !this.wagers.length ||
        !this.$role.can.update('p2e')
      );
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getMiningStatus();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Mining', ['getMiningStatus']),
    changeHandler(switchStatus: boolean): void {
      const handler = switchStatus ? startMining : stopMining;

      handler()
        .then((): void => {
          this.$toast.success(
            `Play to Earn has been turned ${switchStatus ? 'on' : 'off'}`
          );

          this.getMiningStatus();
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        });
    }
  }
};
