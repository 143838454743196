





















































import type { PropType } from 'vue';
import { startCase, toLower } from 'lodash';
import {
  getGameByGameType,
  getGameTypesList,
  updateGame
} from '@/api/GameService';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { GameTypesDropdownItem, GameListItemResponse } from '@/api/schema';

export default {
  name: 'MiningWagersGamesModal',

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    typeName: {
      type: String as PropType<string>,
      default: (): string => ''
    }
  },

  data(): unknown {
    return {
      itemsPerPage: [10, 20, 50],
      search: '',
      searchFilter: '',
      loading: false,
      items: [],
      typesList: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: 300
        },
        {
          text: 'Provider',
          value: 'provider'
        },
        {
          text: 'Game Type',
          value: 'game-type',
          sortable: false,
          width: 180
        }
      ]
    };
  },

  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },

    gameTypesList(): GameTypesDropdownItem[] {
      return this.typesList.map((item: GameTypesDropdownItem) => ({
        ...item,
        type: startCase(toLower(item.type))
      }));
    }
  },

  watch: {
    typeName(value: string): void {
      if (value && this.show) this.getGamesList();
    },
    show(value: boolean): void {
      if (value) this.getGameTypes();
    }
  },

  methods: {
    closeDialog(): void {
      this.$emit('close');
    },

    getGamesList(): void {
      this.loading = true;
      this.items = [];

      getGameByGameType(this.typeName)
        .then((response: GameListItemResponse[]) => {
          this.items = response;
        })
        .catch((error) => errorToastMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },

    searchHandler(): void {
      this.searchFilter = this.search;
    },

    getGameTypes(): void {
      getGameTypesList().then((response) => {
        this.typesList = response;
      });
    },

    updateGameType(game: GameListItemResponse, typeId: number): void {
      updateGame(game.id, typeId).then(() => {
        const deletedIndex = this.items.findIndex(({ id }) => id === game.id);
        this.items.splice(deletedIndex, 1);

        const gameType = this.gameTypesList.find(({ id }) => id === typeId);

        this.$toast.success(
          `Game Type ${gameType.type} assigned to game ${game.name}`
        );
        this.$emit('update');
      });
    }
  }
};
