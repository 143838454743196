



























































import type { PropType } from 'vue';
import type { GameType } from '@/api/schema';
import * as vr from '@/helpers/validation';
import { mapState } from 'vuex';
import { fromPercents, toPercents } from '@/helpers/decimalsHelpers';
import { toTitleCase } from '@/helpers/formatString';

export default {
  name: 'MiningWagersDialog',
  data(): any {
    return {
      wagerData: {
        type: null,
        wager: null
      },
      color: null,
      formIsValid: false
    };
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    data: {
      type: Object as PropType<GameType>,
      default: (): GameType => {
        return {} as GameType;
      }
    },
    dialogTypeCreate: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  computed: {
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('app', ['isSuperAdmin']),
    isLiveWager(): boolean {
      const type = (this.wagerData?.type || '').toUpperCase();
      return type.startsWith('LIVE_') || type === 'LIVE';
    },
    minValue(): number {
      return this.isLiveWager ? 0.00001 : 0.02;
    },
    maxValue(): number {
      return 0.5;
    },
    header(): string {
      if (this.dialogTypeCreate) return 'Add Game Type';

      return toTitleCase(this.data?.type || '');
    },
    maxDigits(): number {
      return this.isLiveWager ? 5 : 3;
    },
    exampleResult(): string {
      if (!this.tokenPrice?.price) {
        return null;
      }

      return `${this.$options.filters.numeralSpaces(
        (this.wagerData.wager * 10) / this.tokenPrice.price,
        '0,0.[0000]'
      )} ${this.tokenSymbol}`;
    },
    wagerValue: {
      get(): number {
        return toPercents(this.wagerData.wager, this.maxDigits) || null;
      },
      set(value: number): void {
        this.wagerData.wager = fromPercents(value, this.maxDigits + 2);
      }
    },
    rules(): any {
      return {
        wagerType: [vr.required],
        wagerValue: [
          vr.required,
          vr.validNumber,
          (v: number) => vr.maxPrecision(v, this.maxDigits),
          !this.isSuperAdmin && this.maxValue
            ? (v: number) => vr.lte(v, this.maxValue)
            : (v: number) => vr.lte(v, 100),
          !this.isSuperAdmin && this.minValue
            ? (v: number) => vr.gte(v, this.minValue)
            : true
        ]
      };
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    data(newValue: GameType): void {
      if (newValue) {
        this.wagerData = { ...newValue };
      }
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        try {
          if (this.formIsValid) {
            this.$emit('submit', this.wagerData);
            this.$emit('close');
            this.$refs.form.reset();
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
  }
};
