






























































































































import { mapActions, mapState, mapGetters } from 'vuex';
import {
  createGameType,
  changeGameType,
  deleteGameType
} from '@/api/GameService';
import MiningWagersDialog from './MiningWagersDialog.vue';
import MiningWagersGamesDialog from './MiningWagersGamesDialog.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import IconCogs from '@/assets/images/icons/cogs-icon.svg?inline';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import type {
  GameType,
  GameTypeChangeRequest,
  GameTypeCreateRequest,
  ConfirmationDialogData
} from '@/api/schema';
import type { MiningState } from '@/store/modules/mining/MiningState';
import { toPercents } from '@/helpers/decimalsHelpers';
import { capitalize, lowerCase } from 'lodash';

interface IWagersData {
  size: number;
  editDialog: boolean;
  createDialog: boolean;
  gamesDialog: boolean;
  isDeleteDialogOpened: boolean;
  deleteDialogData: ConfirmationDialogData;
  isDeleteDialogProcessing: boolean;
  currentWagerData: GameType;
}

const wagerColors = [
  '#FD6C21',
  '#F44336',
  '#E91E63',
  '#673AB7',
  '#FF8A80',
  '#F44336',
  '#3949AB',
  '#D32F2F',
  '#9575CD',
  '#D500F9',
  '#03A9F4',
  '#00BCD4',
  '#009688'
];

const SIZE_LIST = 12;
const OFFSET_SIZE_LIST = 1;

export default {
  name: 'MiningWagers',
  components: {
    MiningWagersDialog,
    MiningWagersGamesDialog,
    ConfirmationDialog,
    IconCogs
  },
  data(): IWagersData {
    return {
      size: SIZE_LIST,
      editDialog: false,
      createDialog: false,
      gamesDialog: false,
      isDeleteDialogOpened: false,
      deleteDialogData: null,
      isDeleteDialogProcessing: false,
      currentWagerData: null
    };
  },
  computed: {
    ...mapState('Mining', {
      wagers: (state: MiningState) => state.wagers.data,
      hasNext: (state: MiningState) => state.wagers.hasNext,
      status: (state: MiningState) => state.enableMining
    }),
    disabled(): boolean {
      return !this.status;
    },
    showControls(): boolean {
      return this.$role.can.update('p2e');
    },
    ...mapState('Mining', ['enableMining', 'loadWagers']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),

    currentWagerName(): string {
      return this.currentWagerData && this.currentWagerData.type;
    },
    offsetSize(): number {
      return this.isSuperAdmin ? OFFSET_SIZE_LIST : 0;
    },
    sizeList(): number {
      return this.size - this.offsetSize;
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.loadWagersList(this.sizeList);
          this.getTokenPriceData();
        }
      },
      immediate: true
    }
  },
  beforeDestroy(): void {
    this.resetWagers();
  },
  methods: {
    ...mapActions('TokensInfo', ['getTokenPriceData']),
    ...mapActions('Mining', ['getWagers', 'resetWagers']),
    getColor(index: number): string {
      return wagerColors[index % wagerColors.length];
    },
    getHumanType(value: string): string {
      return capitalize(lowerCase(value));
    },
    loadMore(): void {
      this.size = this.size + (SIZE_LIST - this.offsetSize);
      this.loadWagersList();
    },
    calculateWager(wager: number): number {
      return toPercents(wager, 5);
    },
    openEditDialog(wager: GameType): void {
      this.currentWagerData = wager;
      this.editDialog = true;
    },
    openCreateDialog(): void {
      this.createDialog = true;
    },
    openGamesDialog(wager: GameType): void {
      this.currentWagerData = wager;
      this.gamesDialog = true;
    },
    closeDialog(): void {
      this.currentWagerData = null;
      this.editDialog = false;
      this.createDialog = false;
      this.gamesDialog = false;
    },
    editDialogHandler(data: GameTypeChangeRequest): void {
      changeGameType(data)
        .then((): void => {
          this.loadWagersList(this.wagers.length);
          this.$toast.success('Wager updated');
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        });
    },
    createDialogHandler(data: GameTypeCreateRequest): void {
      createGameType(data)
        .then(() => {
          if (!this.hasNext) this.loadWagersList(this.sizeList + 1);
          this.$toast.success('Wager created');
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        });
    },
    openDeleteDialogHandler(wager: GameType): void {
      this.currentWagerData = wager;
      this.deleteDialogData = {
        header: `Are you sure to delete game type '${this.getHumanType(
          this.currentWagerData.type
        )}'?`,
        okText: 'Delete'
      };

      this.isDeleteDialogOpened = true;
    },
    closeDeleteDialogHandler(): void {
      this.isDeleteDialogOpened = false;
      this.deleteDialogData = null;
      this.currentWagerData = null;
    },
    async submitDeleteDialogHandler(): Promise<void> {
      try {
        this.isDeleteDialogProcessing = true;

        await deleteGameType(this.currentWagerData.id);
        this.$toast.success(`Game type was successfully deleted!`);
        this.closeDeleteDialogHandler();

        await this.loadWagersList(
          this.hasNext ? this.sizeList : this.wagers.length - 1
        );
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isDeleteDialogProcessing = false;
      }
    },
    async loadWagersList(size: number = this.size): Promise<void> {
      await this.getWagers({ size }).catch((error: AxiosError): void => {
        errorToastMessage(error);
      });
    }
  }
};
